import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Card } from './Card';
import { Input } from './Input';
import { Button } from './Button';

const API_KEY = 'massedotphpeyJhbGciOiJIUzI1NiIsInR5cCI121216IkpXVCJ.n8ZHnAKYnM_llBI8ZL17Ztc84fHNO5JOdcvCfieBVVE'; // Replace with your backend API key
const BACKEND_URL = 'https://api.sedot.dev'; // Local backend URL

const KTPReader = () => {
  const [ktpData, setKtpData] = useState({
    nik: '',
    nama: '',
    tempat_lahir: '',
    tanggal_lahir: '',
    jenis_kelamin: '',
    alamat: '',
    agama: '',
    status_perkawinan: '',
    pekerjaan: '',
    provinsi: '',
    kabupaten_kota: '',
    kecamatan: '',
    kelurahan_desa: '',
    rt: '',
    rw: '',
    golongan_darah: '',
    berlaku_hingga: '',
    kewarganegaraan: '',
    tempat_diterbitkan: '',
    tanggal_diterbitkan: '',
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setKtpData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('KTP Data:', ktpData);
    // Implement data storage or server submission here
    alert('KTP data successfully saved!');
  };

  const processImage = async (file) => {
    setIsProcessing(true);
    setMessage('');
    setIsError(false);
    
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`${BACKEND_URL}/process-ktp`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-API-Key': API_KEY
        }
      });

      const { statusCode, message, data } = response.data;

      if (statusCode === 200 && data) {
        console.log('KTP Processing Result:', data);
        setKtpData(data);
        setMessage(message);
      } else {
        throw new Error(message || 'An error occurred while processing the KTP');
      }

      // Display image preview
      const reader = new FileReader();
      reader.onload = (e) => setPreviewImage(e.target.result);
      reader.readAsDataURL(file);

    } catch (error) {
      console.error('Error processing image:', error);
      setIsError(true);
      setMessage(error.response?.data?.message || error.message || 'An error occurred while processing the image');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB
        setIsError(true);
        setMessage('File size is too large. Maximum 5MB.');
        return;
      }
      try {
        await processImage(file);
      } catch (error) {
        setIsError(true);
        setMessage('Failed to process file. Please try again.');
      }
    } else {
      setIsError(true);
      setMessage('No file selected');
    }
  };

  return (
    <Card className="w-full max-w-2xl mx-auto p-6">
      <h2 className="text-2xl font-bold mb-6 text-center">KTP Data Reader</h2>
      <div className="mb-6">
        <input
          type="file"
          accept="image/*"
          onChange={handleFileUpload}
          ref={fileInputRef}
          className="hidden"
        />
        <Button
          onClick={() => fileInputRef.current.click()}
          disabled={isProcessing}
          className="w-full py-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-200"
        >
          {isProcessing ? (
            <div className="flex items-center justify-center">
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
              Processing...
            </div>
          ) : (
            'Upload KTP Photo'
          )}
        </Button>
      </div>
      {previewImage && (
        <div className="mb-6">
          <img src={previewImage} alt="KTP Preview" className="w-full rounded-lg shadow-md" />
        </div>
      )}
      {message && (
        <div className={`mb-6 p-3 ${isError ? 'bg-red-100 border-red-400 text-red-700' : 'bg-green-100 border-green-400 text-green-700'} border rounded`}>
          {message}
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        {Object.entries(ktpData).map(([key, value]) => (
          <div key={key}>
            <label htmlFor={key} className="block text-sm font-medium text-gray-700 mb-1">
              {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
            </label>
            <Input
              type="text"
              id={key}
              name={key}
              value={value}
              onChange={handleInputChange}
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
            />
          </div>
        ))}
        <Button type="submit" className="w-full py-2 px-4 bg-green-600 text-white rounded hover:bg-green-700 transition duration-200 mt-6">
          Save KTP Data
        </Button>
      </form>
    </Card>
  );
};

export default KTPReader;